import React, { Component } from "react";
import get_users from "./requests/get_users";
import get_projects from "./requests/get_projects";
import moment from "moment";
import Countup from "react-countup";
import { app_url } from "./requests/base_url";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import get_categories from "./requests/fetch_categories";
import get_subcategories from "./requests/fetch_subcategories";
import get_feedback from "./requests/get_feedback";
import InlineTextEdit from "./InlineTextEdit";
import create_category from "./requests/create_category";
import create_subcategory from "./requests/create_subcategory";
import { FEEDBACK_CONTENT_URL } from "./requests/base_url";

interface State {
  users: any[];
  projects: any[];
  categories: any[];
  subcategories: any[];
  feedback: any[];
  error?: string;
  filters: { todayUsers: boolean; todayProjects: boolean; category?: number };
}

export default class Admin extends Component<any, State> {
  state = {
    users: new Array<any>(),
    projects: new Array<any>(),
    categories: new Array<any>(),
    subcategories: new Array<any>(),
    feedback: new Array<any>(),
    error: undefined,
    filters: { todayUsers: false, todayProjects: false, category: undefined },
  };
  async componentDidMount() {
    try {
      const users = await get_users();
      const projects = await get_projects();
      const categories = await get_categories();
      const subcategories = await get_subcategories();
      const feedback = ((await get_feedback()) as any[]).filter((fb) => fb !== ".DS_Store");
      this.setState({ users, projects, categories, subcategories, feedback });
    } catch (err) {
      this.setState({ error: "couldn't fetch data" });
    }
  }

  submitCategory = async (category: string) => {
    try {
      await create_category({ category });
      const categories = await get_categories();
      this.setState({ categories });
      return true;
    } catch (err) {
      this.setState({ error: "couldn't submit category" });
      return false;
    }
  };

  submitSubcategory = async (subcategory: string) => {
    try {
      await create_subcategory({
        category_id: this.state.filters.category || -1,
        subcategory,
      });
      const subcategories = await get_subcategories();
      this.setState({ subcategories });
      return true;
    } catch (err) {
      this.setState({ error: "couldn't submit category" });
      return false;
    }
  };

  render() {
    const QuickStat = (props: { end: number; name: string; className?: string }) => (
      <div className="quick-stat">
        <h1 className={props.className}>
          <Countup end={props.end} /> {props.name}
        </h1>
      </div>
    );

    const createWelcomeEmailLink = (name: string, email: string) =>
      `mailto:${email}?subject=${encodeURIComponent(
        "Welcome To What I'm Building"
      )}&body=${encodeURIComponent(`Hello ${name},
    
Thanks for coming aboard! A few cool features of the site are...
    
Let me know if you have any questions!
- Josh`)}`;

    const isToday = (date: string) => moment(date).isSame(moment.now(), "day");

    return (
      <div className="app">
        <div className="items-header">
          <Link
            className="header-link"
            activeClass="header-link-active"
            to="stats"
            spy
            smooth
            duration={300}
            offset={-100}
          >
            <h1>Stats</h1>
          </Link>
          <Link
            className="header-link"
            activeClass="header-link-active"
            to="users"
            spy
            smooth
            duration={300}
            offset={-100}
          >
            <h1>Users</h1>
          </Link>
          <Link
            className="header-link"
            activeClass="header-link-active"
            to="projects"
            spy
            smooth
            duration={300}
            offset={-100}
          >
            <h1>Projects</h1>
          </Link>
          <Link
            className="header-link"
            activeClass="header-link-active"
            to="categories"
            spy
            smooth
            duration={300}
            offset={-100}
          >
            <h1>Categories</h1>
          </Link>
          <Link
            className="header-link"
            activeClass="header-link-active"
            to="feedback"
            spy
            smooth
            duration={500}
            offset={-100}
          >
            <h1>Feedback</h1>
          </Link>
        </div>
        <Element name="stats">
          <div className="quick-stats-container">
            <QuickStat end={this.state.projects.length} name="Total Projects" />
            <QuickStat end={this.state.users.length} name="Total Users" />
            <QuickStat
              end={this.state.projects.filter((proj) => isToday(proj.created_d)).length}
              name="Projects Created Today"
            />
            <QuickStat
              name="Users Joined Today"
              end={this.state.users.filter((user) => isToday(user.created_d) && user.user_temp === 0).length}
            />
            <QuickStat
              name="Users Invited Today"
              end={this.state.users.filter((user) => isToday(user.created_d) && user.user_temp === 1).length}
            />
          </div>
        </Element>

        <Element name="users">
          <div className="section users-container">
            <button
              className={this.state.filters.todayUsers ? "toggled" : ""}
              onClick={() =>
                this.setState({
                  filters: {
                    ...this.state.filters,
                    todayUsers: !this.state.filters.todayUsers,
                  },
                })
              }
            >
              users joined today
            </button>
            <div className="row th">
              <div>ID</div>
              <div>User Name</div>
              <div>Email</div>
              <div>Joined On</div>
              <div>Contact</div>
            </div>
            {this.state.users.map((user) => {
              const joinedDate = moment(user.created_d);
              return (
                (!this.state.filters.todayUsers || joinedDate.isSame(moment.now(), "day")) &&
                user.user_temp === 0 && (
                  <div className="row user">
                    <a href={`${app_url}/profile/${user.id}`} target="_blank" className="project-id">
                      {user.id}
                    </a>
                    <div className="user-name">
                      {user.firstname} {user.lastname}
                    </div>
                    <div className="user-email">{user.primary_email}</div>
                    <div className="user-join">{joinedDate.format("MM/DD/YYYY")}</div>
                    <a href={createWelcomeEmailLink(`${user.firstname}`, user.primary_email)}>send email</a>
                  </div>
                )
              );
            })}
          </div>
        </Element>
        <Element name="projects">
          <div className="section projects-container">
            <button
              onClick={() =>
                this.setState({
                  filters: {
                    ...this.state.filters,
                    todayProjects: !this.state.filters.todayProjects,
                  },
                })
              }
              className={this.state.filters.todayProjects ? "toggled" : ""}
            >
              Projects Created Today
            </button>
            <div className="row th">
              <div>ID</div>
              <div>Title</div>
              <div>Description</div>
              <div>Category</div>
              <div>Subcategory</div>
              <div>Collaborators</div>
            </div>
            {this.state.projects.map(
              (project) =>
                (!this.state.filters.todayProjects || isToday(project.created_d)) && (
                  <div className="row">
                    <a href={`${app_url}/projects/${project.id}`} target="_blank" className="project-id">
                      {project.id}
                    </a>
                    <div className="project-title">{project.title}</div>
                    <div className="project-desc">{project.proj_desc}</div>
                    <div className="project-category">{project.category}</div>
                    <div className="project-subcategory">{project.subcategory}</div>
                    <div className="project-collabs">{project.collab_count}</div>
                  </div>
                )
            )}
          </div>
        </Element>
        <Element name="categories">
          <div className="section categories-container">
            <div className="categories">
              {this.state.categories.map((category) => (
                <button
                  className={this.state.filters.category === category.id ? "toggled" : ""}
                  onClick={() =>
                    this.setState((state) => ({
                      filters: {
                        ...state.filters,
                        category: category.id === state.filters.category ? undefined : category.id,
                      },
                    }))
                  }
                >
                  {category.category}
                </button>
              ))}
              <InlineTextEdit text="Add Category" onSubmit={this.submitCategory} inline placeholder="New Category" />
            </div>
            <div className="subcategories">
              {this.state.subcategories.map((subcategory) =>
                subcategory.parent_id === this.state.filters.category || this.state.filters.category === undefined ? (
                  <div className="row">
                    <div className="subcategory-name">{subcategory.subcategory}</div>
                  </div>
                ) : null
              )}
              {this.state.filters.category && (
                <div className="row">
                  <InlineTextEdit
                    text="Add Subategory"
                    onSubmit={this.submitSubcategory}
                    inline
                    placeholder="New Subcategory"
                  />
                </div>
              )}
            </div>
          </div>
        </Element>
        <Element name="feedback">
          <div className="feedback-wrapper">
            {this.state.feedback.map((fb) => (
              <img className="feedback-image" src={`${FEEDBACK_CONTENT_URL}/${fb}`} />
            ))}
          </div>
        </Element>
      </div>
    );
  }
}
