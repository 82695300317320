import base_url from "./base_url";
import request from "./request_helper";

export default async function execute(email: string, password: string): Promise<any> {
  return await request({
    method: "POST",
    baseURL: base_url,
    url: `users/loginadmin`,
    data: { email, password },
  });
}
