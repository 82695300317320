const env = window.location.hostname.includes("origin")
  ? "origin."
  : window.location.hostname.includes("staging")
  ? "staging."
  : window.location.hostname.includes("localhost")
  ? "origin."
  : window.location.hostname.includes("admin")
  ? ""
  : "";

export default process.env.NODE_ENV === "development"
  ? "http://localhost:30000/app/api"
  : `https://${env}whatimbuilding.com/app/api`;

export const app_url =
  process.env.NODE_ENV === "development" ? "https://origin.whatimbuilding.com/" : `https://${env}whatimbuilding.com/`;

export const STEP_CONTENT_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:30000/app/stepcontent"
    : `https://${env}whatimbuilding.com/app/stepcontent`;

export const MODULE_CONTENT_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:30000/app/modulecontent"
    : `https://${env}whatimbuilding.com/app/modulecontent`;

export const USER_CONTENT_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:30000/app/usercontent"
    : `https://${env}whatimbuilding.com/app/usercontent`;

export const FEEDBACK_CONTENT_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:30000/app/feedbackcontent"
    : `https://${env}whatimbuilding.com/app/feedbackcontent`;
