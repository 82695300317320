import React, { useState } from "react";
import { get as lsGet } from "local-storage";
import jwt_decode from "jwt-decode";
import Login from "./Login";
import Admin from "./Admin";

interface State {
  needsLogin: boolean;
}

export default class App extends React.Component<any, State> {
  needsLogin = () => {
    const token = lsGet<string>("token");
    const decoded =
      token !== null && token !== undefined && token !== "undefined" ? jwt_decode<{ exp: number }>(token) : { exp: 0 };
    return Date.now() / 1000 > decoded.exp;
  };

  state = { needsLogin: this.needsLogin() };

  render() {
    return this.needsLogin() ? <Login onSuccess={() => this.setState({ needsLogin: false })} /> : <Admin />;
  }
}
