import axios, { AxiosRequestConfig } from "axios";
import { get as lsGet } from "local-storage";

export default async function wrap<T>(request: AxiosRequestConfig) {
  try {
    return (
      await axios({
        ...request,
        headers: {
          Authorization: `bearer ${lsGet("token")}`,
        },
      })
    ).data;
  } catch (err) {
    if (err.response && err.response.data) {
      const { data } = err.response;
      throw new Error(data.error || data.message);
    } else {
      throw new Error("An unexpected error occurred");
    }
  }
}
