import React, { Component } from "react";
import { Formik, Form } from "formik";
import login from "./requests/login";
import { AsyncResource } from "async_hooks";
import * as ls from "local-storage";

interface Values {
  email: string;
  password: string;
}

interface Props {
  onSuccess: VoidFunction;
}

interface State {
  hasError: boolean;
  error: string;
}

export default class Login extends Component<Props, State> {
  state = { hasError: false, error: "" };
  handleSubmit = async (values: Values) => {
    try {
      const response = await login(values.email, values.password);
      console.log(response);
      ls.set("token", response.token);
      this.props.onSuccess();
    } catch (err) {
      this.setState({ hasError: true, error: "couldn't login" });
    }
  };
  render() {
    return (
      <div>
        <Formik initialValues={{ email: "", password: "" }} onSubmit={this.handleSubmit}>
          {(props) => (
            <Form>
              <div className="input">
                <div className="label">email</div>
                <input
                  name="email"
                  onChange={props.handleChange}
                  value={props.values.email}
                  type="text"
                  className="login-email"
                />
              </div>
              <div className="input">
                <div className="label">password</div>
                <input
                  name="password"
                  onChange={props.handleChange}
                  value={props.values.password}
                  type="password"
                  className="login-password"
                />
              </div>
              <button type="submit" className="submit">
                submit
              </button>
            </Form>
          )}
        </Formik>
        {this.state.hasError && this.state.error}
      </div>
    );
  }
}
