import base_url from "./base_url";
import request from "./request_helper";

const env = window.location.hostname.includes("origin")
  ? "origin."
  : window.location.hostname.includes("staging")
  ? "staging."
  : window.location.hostname.includes("localhost")
  ? "origin."
  : window.location.hostname.includes("admin")
  ? "admin."
  : "";

export default async function execute(): Promise<any> {
  return await request({
    method: "GET",
    baseURL: base_url,
    url: `/feedback`,
  });
}
