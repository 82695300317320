import base_url from "./base_url";
import request from "./request_helper";

export interface Args {
  category: string;
}

export default async function execute(args: Args): Promise<any> {
  return await request({
    method: "POST",
    baseURL: base_url,
    url: `/categories`,
    data: args,
  });
}
