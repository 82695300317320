import React, { useState } from "react";

interface Props {
  text: string;
  onSubmit: (text: string) => boolean | Promise<boolean>;
  inline?: boolean;
  placeholder?: string;
}

export default function InlineTextEdit(props: Props) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState("");
  const reset = () => {
    setEditing(false);
    setValue("");
  };
  const submit = async () => {
    const reset = await props.onSubmit(value);
    reset && setEditing(false);
  };
  return (
    <div
      className={`inline-edit-text-container ${editing ? "editing" : ""}`}
      style={{ display: props.inline ? "inline" : "block" }}
    >
      {editing ? (
        <>
          <input
            onKeyUp={(e) => e.which === 13 && submit()}
            className="inline-edit-text-input"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            placeholder={props.placeholder || props.text}
          />
          <button onClick={submit} disabled={value === ""}>
            submit
          </button>
          <button onClick={() => reset()}>cancel</button>
        </>
      ) : (
        <span className="inline-edit-text" onClick={() => setEditing(true)}>
          {props.text}
        </span>
      )}
    </div>
  );
}
